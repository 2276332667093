<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs10>
            <h2>{{ heading }}</h2>
            <span class="subheading">
              {{ subheading }}
            </span>
          </v-flex>
          <v-flex
            xs2
            text-xs-right
          >
            <terms-new-button @newTermAdded="addNewTerm" />
          </v-flex>
        </v-layout>
        <v-divider />
      </v-flex>
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex
            v-for="year in Object.keys(termsByYear)"
            :key="year"
            xs12
          >
            <br>
            <h3>Year {{ year }}</h3>
            <v-layout
              row
              wrap
            >
              <v-flex
                v-for="term in termsByYear[year]"
                :key="term.id"
                xs12
                sm6
                md3
              >
                <v-card hover>
                  <v-card-title>
                    <div>
                      <h3>{{ term.academicYear }} {{ term.name }}</h3>
                      <div>Date: {{ term.startDate }} ~ {{ term.endDate }}</div>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn
                      flat
                      color="indigo"
                      small
                      @click="showSchedules(term)"
                    >
                      View Class
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
            <v-spacer />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="scheduleListDialog"
      max-width="800px"
    >
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>
          {{ `${selectedTerm.academicYear} ${selectedTerm.semester} ${selectedTerm.name}` }}
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :items="schedules"
        :headers="[
          { text: 'Class ID' },
          { text: 'Course ID' },
          { text: 'Start Date' },
          { text: 'End Date' },
          { text: 'Faculty' },
          { text: '' },
        ]"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td>{{ props.item.classID }}</td>
          <td class="text-xs-right">
            {{ props.item.courseID }}
          </td>
          <td class="text-xs-right">
            {{ props.item.startingDate }}
          </td>
          <td class="text-xs-right">
            {{ props.item.endingDate }}
          </td>
          <td class="text-xs-right">
            {{ props.item.facultyID }}
          </td>
          <td class="justify-center layout px-0">
            <v-btn
              small
              class="mr-2"
              @click="goToClass(props.item.csID)"
            >
              Go To
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-dialog>
  </v-container>
</template>

<script>
import TermNewDialogButton from '@/components/term/TermNewDialogButton.vue'

export default {
  name: 'SchoolTerm',
  components: {
    'terms-new-button': TermNewDialogButton,
  },
  data() {
    return {
      terms: [],
      schedules: [],
      selectedTerm: {},
      scheduleListDialog: false,
    }
  },
  computed: {
    heading() {
      return 'School Term'
    },
    subheading() {
      return ''
    },
    termsByYear() {
      const groupBySchoolYear = this.groupBy('academicYear')
      return groupBySchoolYear(this.terms)
    },
  },
  created() {
    this.$store.dispatch('terms/all')
      .then((data) => {
        this.terms = data
      })
  },
  methods: {
    addNewTerm(term) {
      this.terms.push(term)
    },
    groupBy: key => array =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key]
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
        return objectsByKeyValue
      }, {}),
    showSchedules(term) {
      this.$store.dispatch('courseschedules/getByTermID', term.id)
        .then((data) => {
          this.schedules = data
          this.selectedTerm = term
          this.scheduleListDialog = true
        })
    },
    goToClass(csID) {
      this.$router.push(`/courseschedule/${csID}`)
    },
  },
}
</script>

<style>

</style>
