<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-btn
      slot="activator"
      color="primary"
      dark
    >
      New Term
    </v-btn>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New Term</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  v-model="name"
                  label="Name"
                  :counter="50"
                  required
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="academicYear"
                  label="Academic Year"
                  :items="schoolYearOptions"
                  required
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="semester"
                  label="Semester"
                  :items="['Fall', 'Winter', 'Summer']"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="startDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="startDate"
                    label="Starting Date"
                    hint="Date for this found to start taking effect"
                    prepend-icon="event"
                    required
                  />
                  <v-date-picker
                    v-model="startDate"
                    @input="$refs.menu1.save(startDate)"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="endDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="endDate"
                    label="Ending Date"
                    prepend-icon="event"
                    required
                  />
                  <v-date-picker
                    v-model="endDate"
                    @input="$refs.menu2.save(endDate)"
                  />
                </v-menu>
              </v-flex>
              <!-- <v-flex xs12 sm12 md12>
            <v-textarea
              v-model="note"
              label="Note"
              :counter="200"
              box>
            </v-textarea>
          </v-flex> -->
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click.native="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="submit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'TermNewDialogButton',
  data() {
    return {
      dialog: false,
      valid: true,
      menu1: false,
      menu2: false,
      name: null,
      semester: null,
      academicYear: null,
      startDate: null,
      endDate: null,
      note: null,
    }
  },
  computed: {
    schoolYearOptions() {
      const currentYear = new Date().getFullYear()
      return [
        `${currentYear - 1}/${currentYear}`,
        `${currentYear}/${currentYear + 1}`,
        `${currentYear + 1}/${currentYear + 2}`,
      ]
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('terms/create', {
          name: this.name,
          semester: this.semester,
          academicYear: this.academicYear,
          startDate: this.startDate,
          endDate: this.endDate,
        })
          .then((data) => {
            this.clear()
            this.dialog = false
            this.$emit('newTermAdded', data)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
